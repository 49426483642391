import './Loading.scss';

const Loading = () => {
  return (
    <div className="loading__container">
      <div className="loading__spinner"></div>
      Loading...
    </div>
  );
}

export default Loading;