import { ReactComponent as Cloma } from "../icons/profile/cloma.svg";
import { ReactComponent as Deananeas } from "../icons/profile/deananeas.svg";
import { ReactComponent as Almarinez } from "../icons/profile/almarinez.svg";
import { ReactComponent as Canta } from "../icons/profile/canta.svg";
import { ReactComponent as Hagonoy } from "../icons/profile/hagonoy.svg";
import { ReactComponent as Martinez } from "../icons/profile/martinez.svg";
import { ReactComponent as Morales } from "../icons/profile/morales.svg";
import { ReactComponent as Santiago } from "../icons/profile/santiago.svg";


export const creatorsData = [
  {
    name: "Benedict Cloma",
    description: "Hi, I am Brian Benedict Cloma who is thrilled for being a part in the development of the Club for Cubs website. My love of technology and education has been the main source of my motivation, and this initiative has been a focal point of my senior high school years. Being more oriented towards design rather than coding, my primary task is to create user friendly digital interfaces. I would like to develop a user-friendly interface in which the user can move from one place to another without any problems. To me, Club for Cubs isn't simply a website, but a useful tool for students exploring extracurricular activities. Club for Cubs has the power to develop relationships and friendships beyond the classroom through its presentation of information in an appealing and user-friendly manner.",
    image: <Cloma className="creators__logo" />
  },
  {
    name: "Jazmin Deananeas",
    description: "Hi!! I'm Jaz, a contributor of this website whose role involves organizing data and assisting our leader in guiding the group. Excitedly, I engage in this project as it serves as a vital platform for disseminating information among students across various clubs. I firmly believe in the potential of this website to greatly benefit our school community. My enthusiasm for technology has propelled me towards this opportunity, and I am grateful to be part of such an impactful initiative.",
    image: <Deananeas className="creators__logo" />
  },
  {
    name: "Jared Almarinez",
    description: "Good day, I'm Dean Jared D. Almarinez, a contributor and researcher involved in this project. For this projects I had contributed with the concept, ideas and backing related studies used to be able to formulate the concept for this website. This website is a vital part and product of our research project to know it's effectiveness and impact.",
    image: <Almarinez className="creators__logo" />
  },
  {
    name: "Sean Canta",
    description: "Hello, I'm Sean Robert D. Canta and it has been an honor to be a part of making this project possible. I serve as the co-concept artist for the layout of the informational website. My first time entering San Beda University I have already been overwhelmed with a lot of the clubs available to us Senior High School, that is why it is my hope that you dear users may use this website to continue to build new connections and your interactions with your chosen clubs help you to strive not only in your academics but in fostering an enjoyable experience at San Beda University. ",
    image: <Canta className="creators__logo" />
  },
  {
    name: "Rafael Hagonoy",
    description: "Hello I am Rafael E. Hagonoy, Proud participant on the “Clubs for Cubs” website. I'd be pleased to help provide you with an engaging club experience. I'm completely confident that our school community could significantly benefit from this website. My fascination for technology has led me to this chance, and it's an honor to be a part of this wonderful project.",
    image: <Hagonoy className="creators__logo" />
  },
  {
    name: "Russel Martinez",
    description: "Hello Guys, I'm Russell Martinez, Proud Contributor in our website “Clubs for Cubs”. Happy to assist in giving you an exciting experience towards your club choices. As your choices chose you to have a better time in your engaging, impactful, and wonderful club activities.",
    image: <Martinez className="creators__logo" />
  },
  {
    name: "Red Morales",
    description: "Hello I am Red Justin B. Morales. Pleased to assist the San Beda University students with “Clubs for Cubs” for their club decisions and make their utilization of the website more wonderful. As a Bedan Student, we relate this platform to the benedictine hallmark “community”. This will help Bedans to connect with their clubs easily and undoubtedly.",
    image: <Morales className="creators__logo" />
  },
  {
    name: "Aletheia Santiago",
    description: "Hello! I am Aletheia Santiago, and I'm proud to be a content contributor for \"Club for Cubs,\" an informational website displaying the diverse school clubs offered to students. As we embrace new students and welcome back returning students, we wanted to create a platform for students to explore various co-curricular and interest-based club activities, which will help foster a sense of community and engagement within the school. \"Club for Cubs\" promotes holistic development and empowers students to pursue their passions beyond the classroom. \"Club for Cubs\" plays a vital role in shaping the school community by connecting students with similar interests and offering valuable resources.",
    image: <Santiago className="creators__logo" />
  }
];